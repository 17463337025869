const notes = [
  // {
  //   key: 1,
  //   title: "Delegation",
  //   content:
  //     "Q. How many programmers does it take to change a light bulb? A. None – It’s a hardware problem"
  // },
  // {
  //   key: 2,
  //   title: "Loops",
  //   content:
  //     "How to keep a programmer in the shower forever. Show him the shampoo bottle instructions: Lather. Rinse. Repeat."
  // },
  // {
  //   key: 3,
  //   title: "Arrays",
  //   content:
  //     "Q. Why did the programmer quit his job? A. Because he didn't get arrays."
  // },
  // {
  //   key: 4,
  //   title: "Hardware vs. Software",
  //   content:
  //     "What's the difference between hardware and software? You can hit your hardware with a hammer, but you can only curse at your software."
  // }
      // ,
  // {
  //   key: 5,
  //   title: "Delegation",
  //   content:
  //     "Q. How many programmers does it take to change a light bulb? A. None – It’s a hardware problem. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corporis minima veritatis voluptate accusamus delectus voluptatem ipsa autem voluptatibus. Harum alias eveniet qui quasi aliquid quis asperiores facilis at fuga repellat."
  // },
  // {
  //   key: 6,
  //   title: "Loops",
  //   content:
  //     "How to keep a programmer in the shower forever. Show him the shampoo bottle instructions: Lather. Rinse. Repeat."
  // },
  // {
  //   key: 7,
  //   title: "Arrays",
  //   content:
  //     "Q. Why did the programmer quit his job? A. Because he didn't get arrays."
  // },
  // {
  //   key: 8,
  //   title: "Hardware vs. Software",
  //   content:
  //     "What's the difference between hardware and software? You can hit your hardware with a hammer, but you can only curse at your software."
  // }

];

export default notes;